import axios from "axios";

const application = {
  actions: {
    async getApplication(context, application_id) {
      return await axios
        .get(`${context.rootState.url}web/application/${application_id}`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async submitApplication(context, body) {
      return await axios
        .post(`${context.rootState.url}web/application/submit`, body)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async getTest(context, token) {
      return await axios
        .get(`${context.rootState.url}web/test/${token}`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async submitTest(context, params) {
      const body = params.form;
      const token = params.token;
      return await axios
        .post(`${context.rootState.url}web/test/submit/${token}`, body)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async sendApplicationFile(context, params) {
      const file = new File(
        [params.file],
        params.file.name.replace(/[^0-9a-z. ]/gi, ""),
        {
          type: params.file.type,
        }
      );
      const name = params.name;
      const cancel = params.cancel;
      let form = new FormData();
      form.append("file", file);
      form.append("name", name);
      return await axios
        .post(`${context.rootState.url}application/file`, form, {
          headers: { "Content-Type": "multipart/form-data" },
          signal: cancel.signal,
        })
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          cancel.abort();
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
  },
};

export default application;
