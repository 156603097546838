import { createStore } from "vuex";
import axios from "axios";

import navigation from "./navigation.js";
import pages from "./pages.js";
import footer from "./footer.js";
import contact from "./contact.js";
import listing from "./listing.js";
import application from "./application.js";
import validation from "./validation.js";
import testimonials from "./testimonials.js";
import languages from "./languages.js";
import display from "./display.js";

const store = createStore({
  state() {
    return {
      url: process.env.VUE_APP_ROOT_API,
      useCookies: !!localStorage.getItem("cookie"),
    };
  },
  getters: {
    url(state) {
      return state.url;
    },
    useCookies(state) {
      return state.useCookies;
    },
  },
  mutations: {
    setCookies(state) {
      state.useCookies = true;
      localStorage.setItem("cookie", true);
    },
  },
  actions: {
    errorImage(context, event) {
      event.src = require("@/assets/preview.png");
    },
    async getColors(context) {
      return await axios.get(`${context.rootState.url}colors`).then((res) => {
        return {
          success: true,
          data: res.data,
        };
      });
    },
    acceptCookies(context) {
      context.commit("setCookies");
      localStorage.setItem("map", JSON.stringify(context.state.footer.map));
      localStorage.setItem(
        "contactInfo",
        JSON.stringify(context.state.footer.contactInfo)
      );
      localStorage.setItem("icons", JSON.stringify(context.state.footer.icons));
      localStorage.setItem(
        "languages",
        JSON.stringify(context.state.languages.languages)
      );
      localStorage.setItem(
        "language",
        JSON.stringify(context.state.languages.language)
      );
      localStorage.setItem(
        "navigation",
        JSON.stringify(context.state.navigation.navigation)
      );
      localStorage.setItem(
        "logo",
        JSON.stringify(context.state.navigation.logo)
      );
    },
  },
  modules: {
    navigation,
    pages,
    footer,
    contact,
    listing,
    application,
    validation,
    testimonials,
    languages,
    display,
  },
});

export default store;
