const contact = {
  state() {
    return {
      lgAndUp: false,
      md: false,
      sm: false,
      xs: false,
      mobile: false,
    };
  },
  getters: {
    lgAndUp(state) {
      return state.lgAndUp;
    },
    md(state) {
      return state.md;
    },
    sm(state) {
      return state.sm;
    },
    xs(state) {
      return state.xs;
    },
    mobile(state) {
      return state.mobile;
    },
  },
  mutations: {
    setLgAndUp(state, lgAndUp) {
      state.lgAndUp = lgAndUp;
    },
    setMd(state, md) {
      state.md = md;
    },
    setSm(state, sm) {
      state.sm = sm;
    },
    setXs(state, xs) {
      state.xs = xs;
    },
    setMobile(state, mobile) {
      state.mobile = mobile;
    },
  },
  actions: {
    setLgAndUp(context, lgAndUp) {
      context.commit("setLgAndUp", lgAndUp);
    },
    setMd(context, md) {
      context.commit("setMd", md);
    },
    setSm(context, sm) {
      context.commit("setSm", sm);
    },
    setXs(context, xs) {
      context.commit("setXs", xs);
    },
    setMobile(context, mobile) {
      context.commit("setMobile", mobile);
    },
  },
};

export default contact;
