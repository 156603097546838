import { createRouter, createWebHistory } from "vue-router";
import store from "@/stores/store.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/BuilderPage.vue"),
  },
  {
    path: "/page/:id/:title?",
    name: "builderPage",
    component: () => import("@/views/BuilderPage.vue"),
  },
  {
    path: "/job-listings",
    name: "jobListings",
    component: () => import("@/views/JobListings.vue"),
  },
  {
    path: "/testimonials",
    name: "testimonialsPage",
    component: () => import("@/views/Testimonials.vue"),
  },
  {
    path: "/test/:token",
    name: "test",
    component: () => import("@/views/Test.vue"),
  },
  {
    path: "/apply/:listing_id",
    name: "application",
    component: () => import("@/views/Application.vue"),
  },
  {
    path: "/intake",
    name: "intakePage",
    component: () => import("@/views/Intake.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

router.beforeEach(async (to) => {
  store.dispatch("clearSuggested");

  if (to.matched.length === 0) return "/";
});

export default router;
