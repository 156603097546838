<template>
  <v-app-bar class="pb-2 pr-2" color="charcoal" app>
    <v-app-bar-title>
      <router-link class="routerLink" v-on:click="closeAny()" to="/">
        <v-img width="150px" class="mt-2" aspect-ratio="10:2" :src="logo" />
      </router-link>
    </v-app-bar-title>
    <div style="display: flex" v-if="!$vuetify.display.mobile">
      <div v-for="(item, index) in navigation" :key="index" class="mr-5">
        <v-card class="card" color="charcoal">
          <v-btn
            class="pr-2 mt-2"
            :active="dialog[index] || active(item.original)"
            v-on:click="openHeader(index, !dialog[index])"
          >
            {{ item.original.title }}
            <v-icon>
              {{ dialog[index] ? "mdi-menu-up" : "mdi-menu-down" }}
            </v-icon>
          </v-btn>
        </v-card>
        <v-dialog
          v-model="dialog[index]"
          content-class="dialog ma-0"
          open-delay="200"
          hide-overlay
          transition="dialog-top-transition"
          style="z-index: 1000"
        >
          <dropdown @close="dialog[index] = false" :item="item.original" />
        </v-dialog>
      </div>
    </div>
    <span>
      <v-card class="card" color="charcoal" v-if="!$vuetify.display.mobile">
        <v-btn class="mr-4 mt-2" to="/contact">Contact</v-btn>
      </v-card>
    </span>
    <span>
      <v-menu v-if="false">
        <template v-slot:activator="{ props }">
          <div v-bind="props">
            <v-app-bar-nav-icon class="mr-2 mt-2" icon="mdi-account" />
          </div>
        </template>
        <v-list class="mt-5">
          <v-list-item
            v-for="item in profileItems"
            :key="item.title"
            class="px-2"
          >
            <v-btn :to="item.path" width="100%" class="fontFamily">
              {{ item.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </span>
    <span v-if="$vuetify.display.mobile">
      <v-app-bar-nav-icon class="mr-5 mt-2" @click="$emit('toggleSidebar')" />
    </span>
  </v-app-bar>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import { mapGetters } from "vuex";

export default {
  name: "HeaderView",
  components: {
    Dropdown,
  },
  data() {
    return {
      dialog: [],
    };
  },
  computed: {
    ...mapGetters(["navigation", "logo", "parent"]),
  },
  watch: {
    navigation() {
      this.navigation.forEach(() => {
        this.dialog.push(false);
      });
    },
  },
  methods: {
    hover(index) {
      this.dialog = this.dialog.fill(false);
      this.dialog[index] = true;
    },
    closeAny() {
      this.dialog.fill(false);
    },
    openHeader(index, open) {
      this.closeAny();
      if (open) this.dialog[index] = true;
    },
    active(item) {
      const scroll = this.$route.query.scroll ? "/" : "";

      return !!item.sections.find((section) => {
        if (
          this.$route.params.id == section.button_link ||
          this.$route.path == `${section.link}${scroll}` ||
          this.parent == section.button_link
        )
          return true;
        else return false;
      });
    },
  },
  props: {
    profileItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 0 !important;
}
.routerLink {
  font-size: 22px;
  text-decoration: none;
  color: inherit;
}

.v-btn {
  font-size: 16px;
}

.v-toolbar {
  z-index: 1001;
}

.v-btn--active {
  background-color: var(--pop) !important;
}

.v-btn:hover {
  background-color: var(--pop) !important;
}

.v-card {
  box-shadow: none !important;
}
.v-list {
  box-shadow: none !important;
  background-color: var(--light) !important;
}
.v-overlay--active {
  backdrop-filter: blur(1px);
  background: rgb(0 0 0 / 0.2);
}
:deep() .dialog {
  top: 72px;
  width: 100%;
  max-width: 100%;
  left: 0;
}
:deep() .v-overlay__scrim {
  top: 72px !important;
}
</style>
