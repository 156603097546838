import axios from "axios";

const validation = {
  actions: {
    async validate(context, body) {
      return await axios
        .post(`${context.rootState.url}validate`, body)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
  },
};

export default validation;
