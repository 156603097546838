<template>
  <v-card class="pa-5" style="top: 0" color="background">
    <div class="container">
      <v-tabs class="tabs mr-5" v-model="tab" direction="vertical" color="pop">
        <v-tab
          :value="section.title"
          v-for="section in item.sections"
          :key="section.title"
        >
          {{ section.title }}
        </v-tab>
      </v-tabs>
      <v-window class="window" v-model="tab">
        <v-window-item
          v-for="section in item.sections"
          :value="section.title"
          :key="section.title"
          eager
        >
          <v-row>
            <v-col cols="5">
              <v-lazy-image
                v-on:error="errorImage"
                v-on:click="openPage(section.link)"
                :src="section.imageUrl"
                :src-placeholder="require('@/assets/preview.png')"
              />
            </v-col>
            <v-col class="column">
              <div class="right">
                <p class="truncate">{{ section.description }}</p>
                <v-btn
                  :ripple="false"
                  color="pop"
                  class="mb-2"
                  v-on:click="openPage(section.link)"
                  append-icon="mdi-arrow-right"
                >
                  Visit Page
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>

<script>
import VLazyImage from "v-lazy-image";
import { mapActions } from "vuex";

export default {
  name: "DropdownView",
  components: {
    VLazyImage,
  },
  data() {
    return {
      tab: "",
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["errorImage"]),
    openPage(link) {
      if (link?.includes("http")) window.open(link, "_blank").focus();
      else this.$router.push(link);
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.v-card {
  box-shadow: none !important;
  border-radius: 0 !important;
}
.tabs {
  width: 400px;
}
.column {
  height: inherit;
  width: 800px;
}
.v-window {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.right {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-lazy-image {
  width: inherit;
  aspect-ratio: 1/0.8;
  object-fit: cover;
  cursor: pointer;
}
</style>
