import axios from "axios";

const contact = {
  actions: {
    async submitContactForm(context, body) {
      return await axios
        .post(
          `${context.rootState.url}quote/submit/contact_form/submitter`,
          body
        )
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async getReasons(context) {
      return await axios
        .get(`${context.rootState.url}web/reason`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async submitQuoteRequest(context, body) {
      return await axios
        .post(
          `${context.rootState.url}quote/submit/intake_quote/submitter`,
          body
        )
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async sendQuoteFile(context, params) {
      const file = new File(
        [params.file],
        params.file.name.replace(/[^0-9a-z. ]/gi, ""),
        {
          type: params.file.type,
        }
      );
      const company = params.company;
      const job = params.job;
      const request_id = params.request_id;
      let form = new FormData();
      form.append("file", file);
      form.append("company", company);
      form.append("job", job);
      form.append("request_id", request_id);
      return await axios
        .post(`${context.rootState.url}quote/file`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
    async getIntakeLists(context) {
      return await axios
        .get(`${context.rootState.url}web/intake`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
  },
};

export default contact;
