import axios from "axios";

const pages = {
  state() {
    return {
      parent: null,
      suggested: [],
    };
  },
  getters: {
    parent(state) {
      return state.parent;
    },
    suggested(state) {
      return [
        {
          id: -1,
          reference: "/intake",
          icon: "mdi-email-outline",
          title: "Get A Quote",
        },
        ...state.suggested,
      ];
    },
  },
  mutations: {
    setParent(state, parent) {
      state.parent = parent;
    },
    setSuggested(state, suggested) {
      state.suggested = suggested;
    },
    clearSuggested(state) {
      state.suggested = [];
    },
  },
  actions: {
    async page(context, page_id) {
      return await axios
        .get(`${context.rootState.url}page/${page_id}`)
        .then((res) => {
          context.commit("setSuggested", res.data.links);
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    setParent(context, parent) {
      context.commit("setParent", parent);
    },
    clearSuggested(context) {
      context.commit("clearSuggested");
    },
  },
};

export default pages;
