import axios from "axios";
const navigation = {
  state() {
    return {
      navigation: [],
      logo: require("@/assets/logo.png"),
    };
  },
  getters: {
    navigation(state) {
      try {
        return localStorage.getItem("navigation") != null
          ? JSON.parse(localStorage.getItem("navigation"))
          : state.navigation;
      } catch (error) {
        return state.navigation;
      }
    },
    logo(state) {
      try {
        return localStorage.getItem("logo") != null
          ? JSON.parse(localStorage.getItem("logo"))
          : state.logo;
      } catch (error) {
        return state.logo;
      }
    },
  },
  mutations: {
    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
    setLogo(state, logo) {
      state.logo = logo;
    },
  },
  actions: {
    getNavigation(context) {
      axios.get(`${context.rootState.url}web/navigation`).then((res) => {
        context.commit("setNavigation", res.data);
        if (context.rootState.useCookies)
          localStorage.setItem("navigation", JSON.stringify(res.data));
      });
    },
    getLogo(context) {
      axios.get(`${context.rootState.url}logo`).then((res) => {
        context.commit("setLogo", res.data.imageUrl);
        if (context.rootState.useCookies)
          localStorage.setItem("logo", JSON.stringify(res.data.imageUrl));
      });
    },
  },
};

export default navigation;
