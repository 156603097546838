import axios from "axios";
const navigation = {
  state() {
    return {
      languages: [],
      language: "English",
    };
  },
  getters: {
    languages(state) {
      try {
        return localStorage.getItem("languages") != null
          ? JSON.parse(localStorage.getItem("languages"))
          : state.languages;
      } catch (error) {
        return state.languages;
      }
    },
    language(state) {
      return state.language;
    },
  },
  mutations: {
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setLanguage(state, language) {
      state.language = language;
    },
  },
  actions: {
    getLanguages(context) {
      axios.get(`${context.rootState.url}web/list/languages`).then((res) => {
        const languages = res.data.map((language) => language.text);
        context.commit("setLanguages", languages);
        if (context.rootState.useCookies)
          localStorage.setItem("languages", JSON.stringify(languages));
      });
    },
    setLanguage(context, language) {
      context.commit("setLanguage", language);
      if (context.rootState.useCookies)
        localStorage.setItem("language", JSON.stringify(language));
    },
  },
};

export default navigation;
