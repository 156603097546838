<template>
  <v-footer color="charcoal" style="display: block">
    <v-row class="mx-5" justify="center" v-if="contactInfo">
      <div class="ma-5 box">
        <p class="text-h6 title">QES - Manufacturing Facility</p>
        <p class="mt-5 text-subtitle-2 title">
          <v-icon class="mr-2">mdi-phone</v-icon>
          {{ contactInfo.phone }}
        </p>
        <p class="mt-5 text-subtitle-2 title">
          <v-icon class="mr-2">mdi-email</v-icon>
          {{ contactInfo.email }}
        </p>
        <div class="address">
          <v-icon class="mr-2 mt-7">mdi-map-marker-circle</v-icon>
          <p class="mt-5 text-subtitle-2" style="width: 175px">
            {{ contactInfo.address }}
          </p>
        </div>
        <p class="mt-5 text-subtitle-2 title">
          <v-icon class="mr-2">mdi-clock</v-icon>
          {{ contactInfo.hours }}
        </p>
      </div>
      <v-divider
        :vertical="!$vuetify.display.mobile"
        :thickness="2"
        class="border-opacity-100 my-5"
      />
      <div class="ma-5 box">
        <p class="text-h6 title">QES - Inventory Warehouse</p>
        <p class="mt-5 text-subtitle-2 title">
          <v-icon class="mr-2">mdi-phone</v-icon>
          {{ warehouseInfo.phone }}
        </p>
        <div class="address">
          <v-icon class="mr-2 mt-7">mdi-map-marker-circle</v-icon>
          <p class="mt-5 text-subtitle-2" style="width: 225px">
            {{ warehouseInfo.address }}
          </p>
        </div>
        <p class="mt-5 text-subtitle-2 title">
          <v-icon class="mr-2">mdi-clock</v-icon>
          {{ warehouseInfo.hours }}
        </p>
      </div>
      <v-divider
        v-if="icons.length > 0"
        :vertical="!$vuetify.display.mobile"
        :thickness="2"
        class="border-opacity-100 my-5"
      />
      <div :class="`ma-5 ${$vuetify.display.mobile ? 'row' : 'column'}`">
        <v-btn
          v-for="icon in icons"
          :key="icon"
          :icon="icon.icon"
          variant="text"
        />
      </div>
    </v-row>
    <!-- <div
      :class="`${$vuetify.display.mobile ? 'cert-mobile' : ''}`"
      style="max-width: 200px; max-height: 125px; margin-top: -180px"
    >
      <div
        style="
          position: relative;
          padding-bottom: 50%;
          height: auto;
          overflow: hidden;
        "
      >
        <iframe
          frameborder="0"
          scrolling="no"
          allowTransparency="true"
          src="https://cdn.yoshki.com/iframe/55170r.html"
          style="
            border: 0px;
            margin: 0px;
            padding: 0px;
            background-color: transparent;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            position: absolute;
          "
        />
      </div>
    </div> -->
    <v-select
      :class="`${$vuetify.display.mobile ? 'select-mobile' : ''}`"
      variant="underlined"
      :items="languages"
      :model-value="language"
      v-on:update:model-value="$root.changeLanguage"
    />
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterView",
  computed: {
    ...mapGetters([
      "icons",
      "contactInfo",
      "warehouseInfo",
      "languages",
      "language",
    ]),
  },
};
</script>

<style scoped>
.address {
  display: flex;
}
.v-select {
  max-width: 200px;
  margin-top: -75px;
}
.select-mobile {
  max-width: 100%;
  margin-top: 0;
}
.cert-mobile {
  margin-top: -80px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.v-footer {
  padding: 20px;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 46;
}
.row {
  display: flex;
  width: 100%;
  justify-content: center;
}
.box {
  width: 300px;
}
.title {
  white-space: nowrap;
}
</style>
