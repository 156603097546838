<template>
  <div
    class="button ma-5"
    v-if="$route.name === 'builderPage' || $route.name === 'home'"
  >
    <v-menu v-if="suggested.length > 1">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :icon="$vuetify.display.mobile ? 'mdi-book-multiple' : null"
          :prepend-icon="$vuetify.display.mobile ? null : 'mdi-book-multiple'"
          color="pop"
          ref="btn"
          :size="$vuetify.display.mobile ? 'default' : 'x-large'"
          :text="$vuetify.display.mobile ? undefined : 'Related Pages'"
          :style="`transform: ${
            $vuetify.display.mobile ? 'translateY(100px)' : ''
          }`"
        />
      </template>
      <v-list class="mb-5">
        <v-list-item
          v-for="item in suggested"
          :key="item.id"
          :prepend-icon="item.icon"
          :to="item.reference"
        >
          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      :icon="$vuetify.display.mobile ? suggested[0].icon : null"
      :prepend-icon="$vuetify.display.mobile ? null : suggested[0].icon"
      color="pop"
      ref="btn"
      :to="suggested[0].reference"
      :size="$vuetify.display.mobile ? 'default' : 'x-large'"
      :text="$vuetify.display.mobile ? undefined : suggested[0].title"
      :style="`transform: ${
        $vuetify.display.mobile ? 'translateY(100px)' : ''
      }`"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ContactUs",

  mounted() {
    if (this.$vuetify.display.mobile) {
      this.$nextTick(() => {
        document.addEventListener("scroll", this.scroll);
      });
    }
  },

  beforeUnmount() {
    if (this.$vuetify.display.mobile) {
      document.removeEventListener("scroll", this.scroll);
    }
  },

  computed: {
    ...mapGetters(["suggested"]),
  },

  watch: {
    suggested() {
      if (this.$vuetify.display.mobile) this.scroll();
    },
  },

  methods: {
    scroll() {
      if (!this.$vuetify.display.mobile) return;
      if (this.$refs.btn != null) {
        if (window.top.scrollY >= 75)
          this.$refs.btn.$el.style.transform = "translateY(0)";
        else this.$refs.btn.$el.style.transform = "translateY(100px)";
      }
    },
  },
};
</script>

<style scoped>
.button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 500;
}
.v-btn {
  z-index: 100;
  transition: 0.2s transform ease-out;
}
.v-list {
  background-color: var(--blend) !important;
}
</style>
