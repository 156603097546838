import axios from "axios";

const listing = {
  actions: {
    async getListings(context) {
      return await axios
        .get(`${context.rootState.url}web/listings`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async getListingTiles(context) {
      return await axios
        .get(`${context.rootState.url}listingtiles`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error.response.data.message,
          };
        });
    },
  },
};

export default listing;
