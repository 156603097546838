<template>
  <v-card v-if="display" class="button ma-5" color="charcoal" ref="btn">
    <v-card-text>
      <p class="text-subtitle-1">
        This site uses cookies to improve the user experience.
      </p>
      <v-btn color="pop" v-on:click="accept" class="my-2">
        Accept Cookies
      </v-btn>
      <v-btn color="error" v-on:click="check">
        Decline These Improvements
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ContactUs",
  created() {
    this.display = !this.useCookies;
  },
  data: () => {
    return {
      display: true,
    };
  },
  computed: {
    ...mapGetters(["useCookies"]),
  },
  methods: {
    ...mapActions(["acceptCookies"]),
    accept() {
      this.check();
      this.acceptCookies();
    },
    check() {
      this.$refs.btn.$el.style.transform = "translateY(200px)";
    },
  },
};
</script>

<style scoped>
.button {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  max-width: 300px;
  text-align: center;
  transition: 0.2s transform ease-out;
}
.v-btn {
  width: 100%;
}
</style>
